<template>
  <a-form
    ref="formModal"
    :model="formState"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    :label-align="'left'"
    :rules="rules"
    :validate-trigger="['change', 'blur']"
    @finish="onFinish"
    class="form-menu"
  >
    <a-form-item name="name">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        <span class="text-ems-error pr-[2px]">*</span>{{ t('menu.name') }}
      </p>
      <cds-input
        class="w-full !text-ems-text1 ant-input-sm"
        :read-only="disabled"
        maxlength="255"
        show-count
        size="small"
        :disabled="formMode === FORM_MODE.VIEW"
        v-model:value="formState.name"
        :placeholder="t('menu.name')"
      />
    </a-form-item>
    <a-form-item name="clientId">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        <span class="text-ems-error pr-[2px]">*</span>{{ t('menu.phan-he') }}
      </p>
      <a-select
        :disabled="formMode !== FORM_MODE.CREATE"
        :options="phanHe"
        :label-prop="'label'"
        :value-prop="'value'"
        v-model:value="formState.clientId"
        :placeholder="t('menu.phan-he')"
        @select="onSearch"
        size="small"
      />
    </a-form-item>
    <a-form-item name="icon">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        {{ t('menu.menu-icon') }}
      </p>
      <cds-input
        class="w-100 !text-ems-text1 ant-input-sm"
        icon-name="'icon'"
        size="small"
        :icon="'settings'"
        :disabled="disabled"
        :show-count="false"
        :placeholder="t('menu.choose-icon')"
        v-model:value="formState.icon"
        @iconClick="iconClick"
      />
    </a-form-item>
    <a-form-item name="path">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        <span class="text-ems-error pr-[2px]">*</span>{{ t('menu.menu-path') }}
      </p>
      <cds-input
        class="w-100 !text-ems-text1 ant-input-sm"
        :read-only="disabled"
        maxlength="255"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.path"
        :placeholder="t('menu.menu-path')"
      />
    </a-form-item>
    <a-form-item name="parentId">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        {{ t('menu.parent-menu') }}
      </p>
      <cds-tree-select
        v-model:value="formState.parentId"
        :disabled="formMode !== FORM_MODE.CREATE"
        :tree-data="menus"
        :field-names="fieldNames"
        :placeholder="t('menu.parent-menu')"
        :show-search="false"
      />
    </a-form-item>
    <a-form-item name="orderNum">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        <span class="text-ems-error pr-[2px]">*</span>{{ t('menu.order') }}
      </p>
      <a-input-number
        class="w-100 !text-ems-text1 ant-input-sm"
        maxlength="15"
        show-count
        size="small"
        :disabled="formMode !== FORM_MODE.CREATE"
        v-model:value="formState.orderNum"
        :placeholder="t('menu.order')"
        :min="1"
        :max="50"
      />
    </a-form-item>
    <a-form-item name="status">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        {{ t('menu.status') }}
      </p>
      <cds-select
        :options="STATUS"
        :placeholder="t('menu.status')"
        v-model:value="formState.status"
        :disabled="formMode === FORM_MODE.VIEW"
      />
    </a-form-item>
    <div class="w-full flex justify-center mt-[34px]">
      <ClipButton
        v-if="!disabled"
        type="primary"
        size="small"
        style="height: fit-content"
        class="w-max !px-[30px]"
        type-html="submit"
      >
        <span class="text-sm text-ems-white">{{ t('common.save') }}</span>
      </ClipButton>
    </div>
  </a-form>

  <cds-modal
    :visible="visible"
    :mask-closable="false"
    hide-footer
    @onCancel="visible = false"
    class="bg-ems-gray800 pb-0"
  >
    <ModalConfirm
      ref="modal"
      @confirm="handleConfirm"
      @onClose="visible = false"
      :title="t('common.confirm_save')"
    ></ModalConfirm>
  </cds-modal>

  <cds-modal
    :title="titleModal"
    :visible="visibleIcon"
    :width="'50vw'"
    :mask-closable="false"
    hide-footer
    @onCancel="visibleIcon = false"
  >
    <icon-menu ref="modal" @saveSuccess="saveSuccess"></icon-menu>
  </cds-modal>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRaw,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { FORM_MODE } from '@/util/common-constant';
import { maxlength, numberRule, required } from '@/util/validation';
import IconMenu from './IconMenu';
import * as Icons from 'feather-icons';
import { mapFields } from 'vuex-map-fields';
import { formatDate } from '@/util/common-utils';
import { useI18n } from 'vue-i18n';
import { STATUS } from '@/util/common-constant';
import ClipButton from '@/components/buttons/ClipButton.vue';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import {trim} from '@/util/common-utils'
export default defineComponent({
  name: 'Menu',
  components: {
    IconMenu,
    ClipButton,
    ModalConfirm,
  },
  emits: ['saveSuccess'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { state, dispatch } = useStore();
    const formState = reactive(state.menu.menu);
    const formMode = computed(() => state.menu.formMode);
    const formatDateVN = computed(() => formatDate);
    const disabled = formMode.value === FORM_MODE.VIEW;
    const phanHe = [
      { label: t('menu.phan-he-1'), value: 1 },
      { label: t('menu.phan-he-2'), value: 2 },
    ];
    const rules = computed(() => {
      if (disabled) return {};
      return {
        name: [required(t('menu.name')), maxlength(255, t('menu.name'))],
        clientId: [required(t('menu.phan-he'))],
        path: [
          required(t('menu.menu-path')),
          maxlength(255, t('menu.menu-path')),
        ],
        orderNum: [required(t('menu.order')), numberRule(t('menu.order'))],
      };
    });
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 24,
      md: 24,
      xs: 24,
    };
    const formModal = ref(null);
    const fieldNames = { label: 'name', value: 'id' };
    const setDisabled = (menu, disabled) => {
      menu.disabled = disabled;
      if (menu.children) {
        menu.children.forEach((m) => setDisabled(m));
      }
    };
    const icons = Icons.icons;
    const visible = ref(false);
    const menus = computed(() => {
      const menu =
        formState.clientId === 2 ? state.menu.menuVms : state.menu.menuEms;
      menu.forEach((m) => setDisabled(m, disabled));
      return menu;
    });
    const onCancel = () => dispatch('menu/setVisibleMenu', false);
    const onFinish = async () => {
      visible.value = true;
    };
    const handleConfirm = async () => {
      try {
        formState.name =trim(formState.name)
        const data = _.cloneDeep(toRaw(formState));
        if (data.parentId && data.parentId.length > 0) {
          data.parentId = data.parentId.pop();
        }
        await dispatch(
          formMode.value === FORM_MODE.CREATE
            ? 'menu/createMenu'
            : 'menu/updateMenu',
          { ...data, type: 0 }
        ).then((e) => {
          if(e){
            emit('saveSuccess');
            visible.value = false;
          }
          else{
            visible.value = false;
          }

        });
        // eslint-disable-next-line no-empty
      } catch {}
    };
    onMounted(() => {
      dispatch('menu/getMenuEms');
      dispatch('menu/getMenuVms');
    });
    return {
      onFinish,
      onCancel,
      formState,
      rules,
      labelCol,
      wrapperCol,
      menus,
      fieldNames,
      formModal,
      disabled,
      icons,
      dispatch,
      titleModal: t('menu.choose-icon'),
      formatDateVN,
      t,
      phanHe,
      STATUS,
      formMode,
      FORM_MODE,
      visible,
      handleConfirm,
    };
  },
  computed: {
    ...mapFields('menu', ['visibleIcon']),
  },
  methods: {
    iconClick() {
      this.dispatch('menu/preChooseIcon');
    },
  },
});
</script>

<style>
.form-menu .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
}
.form-menu .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-radius: 0px;
  cursor: text;
}
.form-menu .ant-input-number-input, .form-menu .ant-form-item-control-input-content, .form-menu .ant-form-item-control-input-content p {
  color: #b4b4b4 !important;
}
.form-menu .ant-select {
  height: 32px;
}
.form-menu .ant-select .ant-select-selector {
  border: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.form-menu .ant-select .ant-select-selection-placeholder {
  line-height: 26px !important;
}
.form-menu .ant-input-number {
  height: 32px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.form-menu .ant-input-number input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.form-menu .ant-form-item-has-error .ant-input-number, .form-menu .ant-input-number:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity)) !important;
}
</style>
